<template>
  <!-- 根据模板id、合同文件id获取blob数据，后续适配到通用版 -->
  <Modal class="dialog" v-model="pShown" :mask-closable="false" :title="title" :styles="{top: '20px'}" :width="900">
    <!-- <h4 v-if="description">盖章页备注</h4> -->
    <!-- <div class="description" v-html="description"></div> -->
    <!-- <h4 style="padding-top: 20px;">模板内容</h4> -->
    <div v-if="docxData" ref="file" key="doc"></div>
    <div v-else key="loading" style="position: relative; height:400px;">
      <Spin fix>加载中...</Spin>
    </div>
    <div slot="footer">
      <Button type="primary" @click="pShown = false">确 定</Button>
    </div>
  </Modal>
</template>

<script>
// https://www.npmjs.com/package/docx-preview
// https://github.com/zVolodymyr/docxjs
// import docx from 'docx-preview'
import { getTemplateDetail } from '@/api/temp.js'
import { getContractFileDetail } from '@/api/contract.js'

const docx = require('docx-preview')

export default {
  name: 'DocxViewer',
  model: {
    prop: 'info',
    event: 'change'
  },
  props: {
    info: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      pShown: false,
      docxData: null
    }
  },
  computed: {
    title() {
      if (this.info) {
        return this.info.detail.name
      }
      return ''
    },
    description() {
      if (this.info && this.info.detail.description) {
        return this.info.detail.description.replace(/src="/gi, 'src="' + this.$config.baseUrl() + '/')
      }
      return ''
    }
  },
  watch: {
    pShown(newVal) {
      if (newVal) {
        this.initData()
      } else {
        this.$emit('change', null)
        setTimeout(() => {
          this.docxData = null
        }, 300)
      }
    },
    info(newVal) {
      if (newVal) {
        this.pShown = true
      }
    }
  },
  methods: {
    async initData() {
      const docxOptions = Object.assign(docx.defaultOptions, {
        debug: false,
        inWrapper: true,
        experimental: true,
        renderHeaders: true,
        renderFooters: true,
        renderFootnotes: true
      })
      try {
        let res = null
        if (this.info.type == 'temp') {
          res = await getTemplateDetail({ temp_id: this.info.detail.id })
        } else if (this.info.type == 'contract') {
          res = await getContractFileDetail({ id: this.info.detail.id })
        }

        if (res) {
          this.docxData = res.data
          this.$nextTick(() => {
            // 渲染到页面
            docx.renderAsync(this.docxData, this.$refs.file, null, docxOptions)
          })
        }
      } catch (err) {
        this.$Message.error(err.message)
      }
    }
  }
}

</script>

<style lang="less" scoped>
.dialog {
  ::v-deep .ivu-modal-body {
    position: relative;
    min-height: 421px;
    max-height: 650px;
    overflow: hidden scroll;
    padding-top: 5px;
  }
}
</style>
