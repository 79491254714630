import axios from './api.request'

/**
 * 创建资料
 */
export const createContract = (fileDes, params) => {
  // return axios.uploadFile('Contract/create', fileDes, params)
  return axios.uploadFile('Contract/create2', fileDes, params)
}

/**
 * 上传附件
 */
export const uploadAttachment = (fileDes, params) => {
  return axios.uploadFile('Contract/uploadAttachment', fileDes, params)
}

/**
 * 删除附件
 */
export const deleteAttachment = (params) => {
  return axios.request({
    url: 'Contract/deleteAttachment',
    data: params,
    method: 'post'
  })
}

/**
 * 获取资料列表
 */
export const getContractList = (params) => {
  return axios.request({
    url: 'Contract/contractList',
    params,
    method: 'get'
  })
}

/**
 * 获取资料列表
 */
export const shareQRCode = (params) => {
  return axios.request({
    url: 'Contract/shareQRCode',
    params,
    method: 'get'
  })
}

/**
 * 删除资料
 */
export const deleteContract = (params) => {
  return axios.request({
    url: 'Contract/delete',
    data: params,
    method: 'post'
  })
}

/**
 * 获取资料详情
 */
export const getContractDetail = (params, ignoreCodes = null) => {
  return axios.request({
    url: 'Contract/detail',
    params,
    method: 'get',
    ignoreCodes
  })
}

/**
 * 获取资料文件
 */
export const getContractFileDetail = (params) => {
  return axios.request({
    url: 'Contract/fileDetail',
    responseType: 'blob',
    params,
    method: 'get'
  })
}

/**
 * 下载资料的压缩包
 */
export const downloadContractZip = (params) => {
  return axios.request({
    url: 'Contract/download',
    responseType: 'blob',
    params,
    method: 'get'
  })
}

/**
 * 下载签名图片的压缩包
 */
export const downloadSignZip = (params) => {
  return axios.request({
    url: 'Contract/downloadSignImg',
    responseType: 'blob',
    params,
    method: 'get'
  })
}

/**
 * 获取资料签署信息
 */
export const getContractSignInfo = (params) => {
  return axios.request({
    url: 'Contract/signInfo',
    params,
    method: 'get'
  })
}

/**
 * 参与资料
 */
export const joinContract = (params) => {
  return axios.request({
    url: 'Contract/join',
    data: params,
    method: 'post'
  })
}

/**
 * 资料的隐私设置
 */
export const setPrivateSetting = (params) => {
  return axios.request({
    url: 'Contract/setPrivateSetting',
    data: params,
    method: 'post'
  })
}

/**
 * 获取资料日志
 */
export const getContractLog = (params) => {
  return axios.request({
    url: 'Contract/logList',
    params,
    method: 'get'
  })
}

/**
 * 签署资料
 */
export const signContract = (fileDes, params) => {
  return axios.uploadFile('Contract/sign', fileDes, params)
}


/**
 * 填写时历史提示
 */
export const mindTips = (params) => {
  return axios.request({
    url: 'Contract/mindTips',
    params,
    method: 'get'
  })
}