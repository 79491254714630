<template>
	<div>
		<!-- <Modal v-model="pShown" :mask-closable="false" title="生成资料" :width="600"> -->
		<div class="title layout-row">
			<span>模板选择</span>
			<!-- <div style="flex: 1; text-align: center;">
        <Button type="error" ghost v-if="hasData" @click="done">填写模板</Button>
      </div> -->
		</div>
		<template v-if="isNotNeedPay === 0">
			<div>
				<div>抱歉，该功能需要单次充值才能继续使用，或者到个人页<span style="color: red">充值会员</span>以享受最高优惠！</div>
				<div class="pay__panel">
					<template v-if="qrCode">
						<div class="qrcode-box" ref="qrcode"></div>
						<div class="right">
							<div class="price">{{price}}</div>
							<img class="pay_logo" :src="payLogo" />
							<div class="tip">使用微信扫码支付，支付完请稍等片刻！</div>
						</div>
					</template>
					<div v-else-if="qrErrMsg">{{qrErrMsg}}</div>
					<template v-else>支付二维码加载中...</template>
				</div>
			</div>
		</template>
		<template v-else-if="hasData">
			<Form ref="form" :model="form" :rules="rules" :label-width="80">
				<FormItem label="搜索标题" prop="name">
					<Input v-model="form.name" placeholder="请输入资料标题"></Input>
					<div>区域（省，市，县，区）+ 工程名称 + 设备名称 + 出厂编号</div>
				</FormItem>
				<!-- <FormItem label="模板说明" prop="description">
          <Input v-model="form.description"></Input>
        </FormItem> -->
			</Form>
			<span>选择资料模版</span>
			<Table ref="table" class="table" border :columns="columns" :data="tableData"
				@on-selection-change="onSelectionChange">
			</Table>
		</template>
		<div v-else style="position: relative; height: 382px;">
			<Spin fix>加载中...</Spin>
		</div>
		<div slot="footer">
			<!-- <Button type="default" @click="pShown = false">取消</Button> -->
			<!-- <Button v-if="hasData" type="primary" @click="done">确定</Button> -->
			<div class="btn-box" style="padding-top: 20px; padding-bottom: 20px; text-align: right;">
				<Button type="error" ghost v-if="hasData" @click="done">填写模板</Button><!-- class="post-btn" -->
			</div>
		</div>
		<!-- </Modal> -->
	</div>
</template>

<script>
	import {
		getTemplateList
	} from '@/api/temp.js'
	import {
		mapMutations,
		mapGetters
	} from 'vuex'
	import {
		getMemberSetting
	} from '@/api/setting.js'
	import QRCode from 'qrcodejs2'
	import {
		checkReleaseIsPaid,
		getReleasePayQr,
		checkTransaction
	} from '@/api/user'

	export default {
		name: 'TempSelect',
		model: {
			prop: 'shown',
			event: 'change'
		},
		props: {
			shown: {
				type: Boolean,
				default: false
			},
			categoryId: {
				type: Number,
				default: null
			}
		},
		data() {
			return {
				pShown: false,
				hasData: false,
				form: {
					name: '',
					description: ''
				},

				/* 支付相关字段 */
				isNotNeedPay: -1,
				price: 0,
				payLogo: require('@/assets/images/wechat_pay.png'),
				qrCode: null,
				tradeNo: null,
				qrErrMsg: null,
				timers: null,

				tableData: [],
				selections: null
			}
		},
		computed: {
			...mapGetters(['userInfo', 'hasLogin']),
			rules() {
				return {
					name: [{
						required: true,
						message: '请输入资料标题',
						trigger: 'blur'
					}]
				}
			},
			columns() {
				return [{
						type: 'selection',
						width: 55,
						align: 'center'
					},
					/* {
					  type: 'index',
					  title: '序号',
					  width: 80,
					  align: 'center'
					},  */
					{
						key: 'name',
						title: '名称',
						minWidth: 300,
						render(h, params) {
							return h('div', parseFloat(params.row.order) + '、' + params.row.name)
						}
					}
				]
			}
		},
		watch: {
			async pShown(newVal) {
				this.$emit('change', newVal)
				if (newVal) {
					// 如果该用户发布需要收费，则检查是否已付费，否则弹出收费窗口
					let res = await getMemberSetting()
					switch (this.userInfo.vip_info.level) {
						case 0:
							this.price = parseInt(res.content.free.fee.create_contract)
							break
						case 1:
							this.price = parseInt(res.content.level1.fee.create_contract)
							break
						case 2:
							this.price = parseInt(res.content.level2.fee.create_contract)
							break
					}
					if (this.price > 0) {
						res = await checkReleaseIsPaid({
							release_type: 'create-contract'
						})
						this.isNotNeedPay = parseInt(res.content)
					} else {
						this.isNotNeedPay = 1
					}
					// console.log('价格: ' + this.price, '需要支付: ' + this.isNotNeedPay === 0 ? '是' : '否')

					if (this.isNotNeedPay === 0 && (!this.dataRecordInfo || this.dataRecordInfo.is_add)) { // 需要付费且为新增
						// 获取收费二维码
						this.requestPayQr()
					} else {
						this.getTemplateData()
					}
				} else {
					setTimeout(() => {
						this.$refs.form.resetFields()
						Object.assign(this.$data, this.$options.data.call(this))
					}, 300)
				}
			},
			shown(newVal) {
				this.pShown = newVal
			},
			async categoryId(newVal) {
				await this.getTemplateData()
			},
			qrCode(newVal) {
				if (this.$refs.qrcode) {
					this.$refs.qrcode.innerHTML = ''
				}
				if (newVal != null) {
					this.$nextTick(() => {
						this.showQRcode()
					})
				}
			}
		},
		methods: {
			/* 支付 开始 */
			showQRcode() {
				const _ = new QRCode(this.$refs.qrcode, {
					text: this.qrCode, // 需要转换为二维码的内容
					width: 160,
					height: 160,
					colorDark: '#000',
					colorLight: '#fff',
					correctLevel: QRCode.CorrectLevel.H
				})

				if (this.timers) {
					clearInterval(this.timers)
				}

				this.timers = setInterval(async () => {
					await this.getOrderState()
				}, 3000)
			},
			async requestPayQr() {
				this.qrErrMsg = null
				try {
					const params = {
						release_type: 'create-contract'
					}
					const res = await getReleasePayQr(params)
					this.tradeNo = res.content.trade_no
					this.qrCode = res.content.code_url
				} catch (error) {
					this.$Message.error(error.message)
					this.qrCode = null
					this.qrErrMsg = error.message
				}
			},
			async getOrderState() {
				if (this.tradeNo == null) return
				try {
					const params = {
						trade_no: this.tradeNo
					}
					await checkTransaction(params)
					this.$Message.success('支付成功')
					if (this.timers) {
						clearInterval(this.timers)
					}
					this.isNotNeedPay = 1
					this.getTemplateData()
				} catch (error) {}
			},
			/* 支付 结束 */
			// 获取资料模板列表
			async getTemplateData() {
				try {
					const res = await getTemplateList(this.categoryId)
					this.selections = []
					this.tableData = res.content.rows
					this.hasData = true
					this.$nextTick(() => {
						this.$refs.table.selectAll(true)
					})
				} catch (error) {
					this.$Message.error(error.message)
				}
			},
			onSelectionChange(val) {
				this.selections = val
			},
			done() {
				this.$refs.form.validate(async (valid) => {
					if (!valid) {
						return
					}

					if (this.selections == null || this.selections.length == 0) {
						this.$Message.error('至少选择一个资料模版')
						return
					}
					const temp_ids = JSON.stringify(this.selections.map(item => {
						return item.id
					}))

					const detail = {
						name: this.form.name,
						description: this.form.description,
						temp_ids,
						temps: this.selections
					}

					this.$emit('on-select', detail)
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.dialog ::v-deep .ivu-modal-body {
		padding: 16px 30px;
	}

	.table {
		margin-top: 5px;

		// height: 240px;
		::v-deep .ivu-table-body {
			height: ~"calc(100% - 39px)";
			overflow: hidden auto;
		}
	}

	.pay__panel {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 200px;

		.right {
			display: flex;
			height: 160px;
			flex-direction: column;
			justify-content: center;
			margin-left: 20px;

			.price {
				color: #ff3a3a;
				text-align: center;
				font-size: 40px;
				line-height: 60px;
				font-weight: 500;

				&::after {
					content: " 元";
					font-size: 14px;
				}
			}

			.pay_logo {
				flex-shrink: 0;
				width: 120px;
				height: 40px;
				object-fit: contain;
			}

			.tip {
				line-height: 40px;
				font-size: 15px;
			}
		}

		.err {
			text-align: center;
		}
	}

	.qrcode-box {
		display: inline-block;
		width: 160px;
		height: 160px;
	}

	.title {
		color: #333;
		font-size: 18px;
		font-weight: 500;
		margin: 10px 0 20px;
	}

	.post-btn {
		position: absolute;
		top: 25px;
		right: 50px;
		// color: #ff552e;
	}
</style>
