import axios from './api.request'

/**
 * 获取 template 页面数据
 */
export const getTemplateData = (category_id = null) => {
  return axios.request({
    url: 'Temp/index',
    params: {category_id},
    method: 'get'
  })
}

/**
 * 获取资料模版列表
 */
export const getTemplateList = (category_id = null) => {
  return axios.request({
    url: 'Temp/tempList',
    params: {category_id},
    method: 'get'
  })
}

/**
 * 获取模版详情
 */
export const getTemplateDetail = (params, ignoreCodes = null) => {
  return axios.request({
    url: 'Temp/tempDetail',
    responseType: 'blob',
    params,
    method: 'get',
    ignoreCodes
  })
}
