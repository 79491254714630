<template>
  <div v-if="categoryList" class="content">
    <div class="left__bd">
      <CellGroup class="cell__panel" @on-click="onCategoryClick">
        <Cell v-for="(item, index) in categoryList" :key="item.id" :title="item.name" :selected="selectedIndex == index"
          :name="index">
          <Icon slot="extra" type="ios-arrow-forward" />
        </Cell>
      </CellGroup>
    </div>
    <div class="right__bd">
      <div class="table__panel">
        <!-- <Button type="error" ghost class="post-btn" @click="onCreateTemp">生成资料</Button>
        <div class="title">{{categoryName}}模版</div>
        <Table ref="table" class="table" :columns="columns" :data="tableData"> -->
        <!-- <template slot-scope="{row}" slot="action">
            <Button @click="onViewTempRow(row)" type="primary" size="small" style="margin-left:10px">查看</Button>
          </template> -->
        <!-- </Table> -->
        <temp-select v-model="showTempSelect" @on-select="onSelectSuccess" :category-id="categoryId" />
      </div>
    </div>
    <docx-viewer v-model="tempInfo" />
    <!-- <temp-select v-model="showTempSelect" @on-select="onSelectSuccess" :category-id="categoryId"/> -->
  </div>
</template>

<script>
import { getTemplateData, getTemplateList } from '@/api/temp.js'
import { mapMutations } from 'vuex'
import DocxViewer from '@/components/DocxViewer'
import TempSelect from '@/components/TempSelect'

export default {
  name: 'Temp',
  components: {
    DocxViewer,
    TempSelect
  },
  data() {
    return {
      selectedIndex: 0,
      categoryList: null,
      categoryId: null,
      tableData: [],

      tempInfo: null,
      showTempSelect: false
    }
  },
  computed: {
    columns() {
      return [{
        key: 'name',
        title: '名称',
        minWidth: 300,
        render(h, params) {
          return h('div', parseFloat(params.row.order) + '、' + params.row.name)
        }
      }/* , {
        slot: 'action',
        title: '操作',
        width: 140,
        align: 'center'
      } */]
    },
    categoryName() {
      if (this.categoryList) {
        return this.categoryList[this.selectedIndex].name
      }
      return '资料'
    }
  },
  async mounted() {
    await this.initData()
    this.showTempSelect = true
  },
  watch: {
  },
  methods: {
    ...mapMutations(['showLoginDialog']),
    async initData() {
      try {
        const res = await getTemplateData()
        this.categoryList = res.content.category_list
        this.tableData = res.content.temp_list
      } catch (error) {
        this.$Message.error(error.message)
      }
    },
    async onCategoryClick(name) {
      this.selectedIndex = parseInt(name)
      const category_id = this.selectedIndex > 0 ? this.categoryList[this.selectedIndex].id : null
      this.categoryId = category_id
      this.tableData = []
      try {
        const res = await getTemplateList(category_id)
        this.tableData = res.content.rows
      } catch (error) {
        this.$Message.error(error.message)
      }
    },
    onCreateTemp() {
      if (!this.$store.getters.hasLogin) {
        this.showLoginDialog(true)
      } else {
        this.showTempSelect = true
      }
    },
    onViewTempRow(row) {
      if (!this.$store.getters.hasLogin) {
        this.showLoginDialog(true)
      } else {
        // this.tempInfo = {type: 'temp', detail: row}
        window.open('https://view.officeapps.live.com/op/view.aspx?src=' + encodeURIComponent(row.url + "?t=" + Date.now()))
      }
    },
    onSelectSuccess(detail) {
      this.$router.push({ name: 'temp-create', params: detail })
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
}

.left__bd {
  position: relative;
  height: 100%;
  flex: 1;
  min-width: 220px;
  border-right: solid 1px #dbdde1;
  overflow: hidden auto;

  .cell__panel {
    width: 200px;
    margin: 20px 0;
    float: right;
  }
}

.left__bd::-webkit-scrollbar {
  width: 0;
}

.right__bd {
  position: relative;
  height: 100%;
  flex: 2;
  overflow: hidden auto;

  .table__panel {
    position: relative;
    padding: 20px;
    min-width: 500px;
    max-width: 780px;

    .title {
      color: #333;
      font-size: 18px;
      font-weight: 500;
      margin: 10px 0 20px;
    }
  }
}

.table ::v-deep .ivu-table th {
  background-color: #e8ecf5;
}

.post-btn {
  position: absolute;
  top: 25px;
  right: 50px;
  // color: #ff552e;
}
</style>
